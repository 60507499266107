import { render } from "./403.vue?vue&type=template&id=6b2cd38d&scoped=true"
import script from "./403.vue?vue&type=script&lang=js"
export * from "./403.vue?vue&type=script&lang=js"

import "./403.vue?vue&type=style&index=0&id=6b2cd38d&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-6b2cd38d"
/* hot reload */
if (module.hot) {
  script.__hmrId = "6b2cd38d"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6b2cd38d', script)) {
    api.reload('6b2cd38d', script)
  }
  
  module.hot.accept("./403.vue?vue&type=template&id=6b2cd38d&scoped=true", () => {
    api.rerender('6b2cd38d', render)
  })

}

script.__file = "src/views/403.vue"

export default script